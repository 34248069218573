@import url('https://fonts.googleapis.com/css?family=Open+Sans');
*{
    margin: 0;
    padding: 0;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.header-table {
    width:100%;
}
.page-box {
    font-size: 10pt;
    line-height: 1.3;
    font-family: 'Open Sans', sans-serif;
    /* font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; */
    color: #000;
    width: 216mm;
    height: 279mm;
    padding: 10mm;
}
.page-box-html {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 20px #ccc; 
    margin: auto;
}

.bill-to-boxes {
    width: 100%;
    margin-top: 5mm;
}
.bill-to-boxes table {
    width: 100%;
    height: 100%;
}
.bill-to-box {
    width: 60mm;
    min-height: 22mm;
}
.subtotals th {
    border-bottom: 0 !important;
    text-align: right; 
}
.subtotals td {
    border-bottom: 0 !important;
}
.subtotals td:last-child {
    border-bottom: 1px solid #dee2e6 !important;
}

.invoice-table th, .invoice-table td {
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    padding: .5rem;
}
.invoice-table th {
    border-bottom: 1px solid #555;
}

.invoice-table {
    width: 100%;
    border-top: 2px solid #555;
}
.invoice-div {
    margin-top: 7mm;
    min-height: 130mm;
}
.paragraph {
    margin-bottom: 2rem;
}
.delivery-box {
    margin-top: 10mm;
}
.invoice-title {
    font-size: 20pt;
    font-weight: bold;
    color:#555;
    /* margin-top:-65px; */
}
.invoice-title-box {
    height: 100%;
}
.invoice-title-td {
    height: 1px;
}
.invoice-number {
    margin-top: 1.5rem;
}

.bottom-box {
    padding-top: 1rem; 
    border-top: 1px solid #ccc;
    line-height: 1.5;
    page-break-inside:avoid; 
}
.text-weight-bold {
    font-weight: bold;
}
.high-line {
    line-height: 1.5;
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.imt-2 {
    margin-top:2rem;
}
.imt-03 {
    margin-top:.2rem;
}
.delivery-date {
    margin-left: 35mm;
}
i {
    font-size: .7rem;
}
table {
    border-collapse: collapse;
}
@media only print and (min-width: 480px) {
    .page-box {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        box-shadow: 0;
        color: #000;
    }
    .page-box-html {
        border: 0px solid #ccc;
        border-radius: 0;
        box-shadow: 0; 

    }
    .close {
        display: none;
    }
}
.invoice-logo {
    width:100%; 
    max-width:250px;
}
#invoiceDialog {
    padding-right: 300px;
}