@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Merriweather+Sans:400,400i,700|Montserrat:400,400i,700|Open+Sans:400,400i,700|Oswald:400,700|Roboto:400,400i,700);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

*{
    margin: 0;
    padding: 0;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.header-table {
    width:100%;
}
.page-box {
    font-size: 10pt;
    line-height: 1.3;
    font-family: 'Open Sans', sans-serif;
    /* font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif; */
    color: #000;
    width: 216mm;
    height: 279mm;
    padding: 10mm;
}
.page-box-html {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 20px #ccc; 
    margin: auto;
}

.bill-to-boxes {
    width: 100%;
    margin-top: 5mm;
}
.bill-to-boxes table {
    width: 100%;
    height: 100%;
}
.bill-to-box {
    width: 60mm;
    min-height: 22mm;
}
.subtotals th {
    border-bottom: 0 !important;
    text-align: right; 
}
.subtotals td {
    border-bottom: 0 !important;
}
.subtotals td:last-child {
    border-bottom: 1px solid #dee2e6 !important;
}

.invoice-table th, .invoice-table td {
    vertical-align: top;
    border-bottom: 1px solid #dee2e6;
    padding: .5rem;
}
.invoice-table th {
    border-bottom: 1px solid #555;
}

.invoice-table {
    width: 100%;
    border-top: 2px solid #555;
}
.invoice-div {
    margin-top: 7mm;
    min-height: 130mm;
}
.paragraph {
    margin-bottom: 2rem;
}
.delivery-box {
    margin-top: 10mm;
}
.invoice-title {
    font-size: 20pt;
    font-weight: bold;
    color:#555;
    /* margin-top:-65px; */
}
.invoice-title-box {
    height: 100%;
}
.invoice-title-td {
    height: 1px;
}
.invoice-number {
    margin-top: 1.5rem;
}

.bottom-box {
    padding-top: 1rem; 
    border-top: 1px solid #ccc;
    line-height: 1.5;
    page-break-inside:avoid; 
}
.text-weight-bold {
    font-weight: bold;
}
.high-line {
    line-height: 1.5;
}

.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.imt-2 {
    margin-top:2rem;
}
.imt-03 {
    margin-top:.2rem;
}
.delivery-date {
    margin-left: 35mm;
}
i {
    font-size: .7rem;
}
table {
    border-collapse: collapse;
}
@media only print and (min-width: 480px) {
    .page-box {
        margin: 0;
        width: 100%;
        height: 100%;
        padding: 0;
        box-shadow: 0;
        color: #000;
    }
    .page-box-html {
        border: 0px solid #ccc;
        border-radius: 0;
        box-shadow: 0; 

    }
    .close {
        display: none;
    }
}
.invoice-logo {
    width:100%; 
    max-width:250px;
}
#invoiceDialog {
    padding-right: 300px;
}
body {
  position: relative;
  background-color: #551320;
  transition: all ease-in-out 250ms;
}
.navbar-brand img {
  height: 30px;
}
.navbar-dark {
  background-color: #093648;
}
#navbar-bar {
  background-color: #093648;
}
#home {
  background: url(/images/industria3.1L.jpg);
  background-position: -700px 50px;
  height: 600px;
  display: flex;
  align-items: center;
}
#home .content {
  width: 60vw;
  background-color: rgba(255, 255, 255, 0.6);
  margin: 10px auto;
}
#home .logo {
  width: 100%;
  padding: 20px;
}
.logo-box {
  transition: initial;
}
#about {
  background-color: white;
  padding: 30px;
  padding-top: 65px;
}
#about img {
  /* border-radius: 50%; */
  margin-right: 20px;
  width: 350px;
}
#about .row {
  margin-top: 30px;
  margin-bottom: 40px;
}
#about .content {
  margin-left: 10px;
  font-size: 18pt;
}
#about h2 {
  color: #551320;
}
#granite {
  color: #551320;
  padding: 40px;
  padding-top: 65px;
  background-color: #eee;
}
.granite-view img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.granite-vcard {
  display: inline-table;
  margin: 0 9px 34px 9px;
  background: trasparent;
  text-align: center;
  width: 150px;
}
.granite-vcard-subtitle {
  font-size: 10pt;
  color: #797596;
}
.carousel-inner {
  width: 720px;
  margin: auto;
}
.carousel-inner img {
  width: 100%;
}
.carousel-caption {
  background-color: rgba(0, 103, 164, 0.5);
}
.granite-view {
  padding: 40px;
}
.call {
  text-align: center;
  color: #946421;
  font-size: 1.8em;
  font-family: "Merriweather Sans", sans-serif;
  background-color: #0067a4;
  padding: 60px 0;
}
.call a {
  font-family: "Montserrat", sans-serif;
}
.call .contacts::before {
  content: "";
  background-color: #ccc;
  display: inline-block;
  width: 150px;
  height: 6px;
  margin-right: 30px;
  margin-bottom: 6px;
}
.call .contacts::after {
  content: "";
  background-color: #ccc;
  display: inline-block;
  width: 150px;
  height: 6px;
  margin-left: 30px;
  margin-bottom: 6px;
}
#contact {
  color: white;
  background-color: #944e00;
  padding: 60px 0;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Merriweather Sans", sans-serif;
  align-content: center;
  text-align: center;
  font-weight: bold;
}
h2 {
  font-size: 42px;
}
#contact p {
  /* font-family: 'Roboto', sans-serif; */
  font-size: 1.4em;
}
.nav-item {
  font-size: 14pt;
  font-weight: bold;
  font-family: "Merriweather Sans", sans-serif;
}
.nav-item-separator {
  margin: 6px;
  color: rgba(255, 255, 255, 0.616);
}
.dropdown-menu {
  background-color: #093648;
}
.dropdown-item:hover {
  background-color: #093648;
}
.dropdown-divider {
  border: 0.5px solid #082e3d;
}
.all-fonts {
  /* fonts and colors used in the site*/
  font-family: "Merriweather Sans", sans-serif;
  font-family: "Montserrat", sans-serif;
  font-family: "Open Sans", sans-serif;
  /* font-family: 'Oswald', sans-serif;
	font-family: 'Roboto', sans-serif; */

  background-color: #093648;
  background-color: #482a09;
  background-color: #551320;
  background-color: #217494;
  background-color: #0067a4;
}
footer {
  background-color: #551320;
  color: white;
  padding: 40px;
}
footer img {
  width: 40px;
}
a.contacts {
  color: white;
}
#map {
  height: 550px;
}
@media only screen and (max-width: 768px) {
  header .logo-box {
    width: 80vw;
    margin: 5vw;
    margin-top: -400px;
  }
  #home {
    height: 80vh;
  }
  .carousel-inner {
    width: 100vw;
    margin-left: 0;
  }
  body {
    background-size: 100vw;
  }
  #about img {
    /* border-radius: 50%; */
    margin-right: 1px;
    width: 80%;
  }
  .carousel-inner {
    width: 80vw;
    margin: auto;
  }

  .nav-item-separator {
    display: none;
  }
  .call .contacts::before,
  .call .contacts::after {
    display: block;
    margin: 4px auto;
  }
  #home {
    background-position: -1100px 50px;
  }
  #map {
    height: calc(100vw - 80px);
  }
  .logo {
    width: 90vw;
  }
  .right-panel {
    display: none;
  }
  .bottom-panel td {
    display: table-row;
  }
}

@media only screen and (min-width: 769px) {
}

.granite-slider {
  width: 900px;
  display: block;
  text-align: center;
  margin: auto;
}

@media only screen and (max-width: 637px) {
  .granite-card {
    width: 100%;
  }
  .granite-slider {
    width: 100%;
  }
}
/* shapes */
.shapes {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: 1200px;
  background: #000;
  box-shadow: 0 25px 30px rgba(0, 0, 0, 0.5);
  display: flex;
}
.content-box {
  width: calc(50% - 50px);
  height: 100%;
  background: #000;
  box-sizing: border-box;
  color: #fff;
  font-size: 1.1em;
  padding: 30px 50px;
}
.image-box {
  width: calc(50% + 50px);
  height: 100%;
  background: #f00;
  box-sizing: border-box;
  border-left: 100px solid #000;
  border-bottom: 400px solid transparent;
  background: url("/images/brazil-quarry2.jpg");
}
.btn-gap {
  margin-left: 5px;
  margin-right: 5px;
}

.price-sheet {
  background-color: white;
  color: #000;
  padding: 10px;
  padding-top: 60px;
  font-family: "Open Sans", sans-serif;
}

.vertical {
  padding-left: 50px;
  writing-mode: tb-rl;
}
.ver-div {
  white-space: nowrap;
  width: 100%;
}

.vertical3 {
  transform: rotate(90deg);
}

.price-table th {
  color: #fff;
  background-color: #4472c4;
}

.table th,
.table td {
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.price-table tbody tr:nth-of-type(odd) {
  background-color: #b4c6e7;
}

.price-table tbody tr:nth-of-type(even) {
  background-color: #d9e1f2;
}

.price-table tbody tr {
  font-size: 13pt;
}

.price-table td,
.price-table th {
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #fff;
  padding-left: 7px;
}

.side-table td {
  background-color: #0000;
  height: 100%;
  /* border: 1px solid #bebebe; */
}

.side-table-div {
  margin: 0;
}

.price-header {
  padding-bottom: 20px;
}

.limited-heade {
  font-size: 14pt;
}

.spinner {
  border-radius: 50%;
  border-top: 5px solid #bbb;
  border-bottom: 5px solid #bbb;
  border-left: 5px solid #bbb;
  border-right: 5px solid rgba(200, 200, 200, 0);
  width: 60px;
  height: 60px;
  animation: spin 1.5s linear infinite;
  position: fixed;
  left: calc(50% - 60px);
  top: 75px;
  z-index: 1100;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
}

.link-button:hover,
.link-button:focus {
  border: none;
  text-decoration: none;
  outline: none;
}

.user-avatar {
  height: 32px;
  width: 32px;
  border-radius: 25em;
  border-top-left-radius: 25em;
  border-top-right-radius: 25em;
  border-bottom-right-radius: 25em;
  border-bottom-left-radius: 25em;
  object-fit: cover;
}

.granite-card {
  height: 198px;
}

.slab-card-img {
  height: 180px;
}

.slab-card {
  margin: 20px;
  border: 5px solid #6c757d;
  /* width: 18rem; */
  background-color: white;
}

.page-div {
  min-height: 100vh;
  background-color: #fff;
  padding-top: 90px;
  padding-bottom: 60px;
}

.page-s {
  min-height: 100vh;
  background-color: #fff;
  margin: 0;
  padding-top: 70px;
  padding-bottom: 64px;
  padding-right: 20px;
  font-family: "Open Sans";
  font-size: 14px;
}

.card-text-group p {
  line-height: 0.7;
}

.card-body {
  color: #4d4d4d;
  font-family: "Open Sans", sans-serif;
}

.table-total {
  color: #bb1320;
  border-bottom: solid 1px #ccc !important;
}

.table-subtotal {
  border-bottom: solid 1px #ccc !important;
}

.total-row-empty {
  border: none !important;
}

.total-row-top {
  border-bottom: none !important;
  border-top: 1px solid #dee2e6 !important;
}

.bigger {
  width: 1.2em;
  height: 1.2em;
}

.bigger-ck {
  width: 20px !important;
  height: 20px !important;
}
.reserved-lock {
  position: absolute;
  top: 0;
  right: 10px;
  color: #fffa;
  font-size: 40px;
}

@media only screen and (max-width: 480px) {
  .lh-13 {
    line-height: 1.3;
  }
}

input[type="search"] {
  -webkit-appearance: searchfield;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

.text-md-18 {
  font-size: 20px;
}

.modal-panel {
  margin-top: 80px;
}

.my-auto {
  margin: auto 0;
}

.mx-auto {
  margin: 0 auto;
}

.bg-lighter {
  background-color: #e9ecef;
}

.invoice-page {
  font-family: "Open Sans", sans-serif;
  min-height: 100vh;
}
.invoice-container {
  padding: 50px;
  border: 2px #eee solid;
}

.ReactModal__Content {
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.175);
  transform: translate(0, -350px);
}

.ReactModal__Content--after-open {
  transform: translate(0, 0px);
}

.ReactModal__Content--before-close {
  transform: translate(0, -350px);
  transition: all 300ms linear;
}

.d-addr-card {
  width: 18rem;
  border: 1px solid #aaa;
}

.d-addr-card .card-head {
  background-color: #212529;
  color: #fff;
  text-align: center;
  padding: 12px;
  font-weight: bold;
}

.schedule-detail {
  width: 100%;
  display: grid;
  grid-template-columns: 0.7fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  border-left: solid 3px #17a2b8;
  color: #787878;
  padding: 0.5rem 1rem;
}

.schedule-time {
  width: 132px;
  margin: auto 1rem;
  text-align: right;
}

.schedule-detail span {
  font-weight: bold;
}

.schedule-panel {
  font-family: "Open Sans", sans-serif;
}

.code-view {
  font-size: 12px;
  font-family: Consolas, "Lucida Console", monospace;
}

.count-label {
  background-color: #e9ecef;
  color: #8e99a3;
  padding: 0.4em 4em;
  border: solid 1px #dee2e6;
  border-radius: 25px;
}

.slide-left {
  transform: translate(0);
  transition: transform 1s 0.5s ease;
  opacity: 1;
}

.slide-left-after {
  height: 0;
  width: 0;
  transform: translate(900px);
  transition: transform 1s ease;
}

.slide-right {
  height: 100%;
  transform: translate(0px);
  transition: all 1s ease;
  opacity: 1;
}

.slide-right-after {
  height: 0;
  transform: translate(-1200px);
  transition: all 1s ease;
  opacity: 0;
  visibility: collapse;
}

.settings-box {
  border: 1px solid #dee2e6;
  padding: 20px 20px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 1px 1px 2px #aaa;
  margin: 10px 0;
}

.price-card {
  width: 600px;
  margin-top: 7px;
  margin-bottom: 7px;
  background-color: #f8f9fa !important;
  display: flex !important;
}

.price-card-description {
  width: 100%;
  padding: 5px 10px;
}

.price-card-image {
  height: 150px;
  width: 150px;
  min-width: 150px;
  min-height: 150px;
  background: #ddd;
}

.price-card-tools {
  width: 150px;
  margin-right: 5px;
  margin-top: 15px;
}

.sort-handle {
  cursor: move;
}

.click-handle {
  cursor: pointer;
}

.sort-ghost-look {
  opacity: 0;
  background-color: #0000;
  cursor: move;
  color: #fff;
}

/* below everything  */
@media only screen and (max-width: 769px) {
  .d-addr-card {
    width: 100%;
  }
  .schedule-detail {
    display: block;
  }
  .side-extra {
    display: none;
  }
  .main-extra {
    width: 100% !important;
  }
  .count-label {
    padding: 0.3em 1.4em;
  }
  .price-card {
    width: 500px;
  }
  .price-list-container {
    overflow-x: scroll;
  }
}

@media print {
  body {
    background-color: #fff;
  }
  .price-sheet {
    padding: 0px !important;
    margin-top: 0px !important;
  }
  thead.thead-dark th {
    background-color: #212529 !important;
  }
  .btn {
    display: none;
  }

  .pdf-wrap {
    background: #fff;
    margin-top: 0;
  }
}

/* d3 tree styles */
.node rect {
  cursor: pointer;
  fill: #fff;
  fill-opacity: 0.5;
  stroke: #3182bd;
  stroke-width: 1.5px;
}
.node text {
  font: 14px sans-serif;
  pointer-events: none;
}

.link {
  fill: none;
  stroke: #0000;
  stroke-width: 1.5px;
}

div:focus {
  outline: 0;
}

.card-light {
  border: solid 1px #dfdfdf;
}

.table-delivery th {
  background: #343440;
  color: #fff !important;
}

.pdf-wrap {
  background: #f8f8f8;
  text-align: center;
  margin-top: 55px;
}

.bg-grey {
  background-color: #f2f2f2;
}

g.hidden line,
g.hidden path {
  display: none;
}

text.legend-text {
  font-size: 8pt;
  fill: #707070;
}

.tb-report tr th:last-child,
.tb-report tr td:last-child {
  text-align: right;
}

li {
  margin-left: 1em;
}

.terms-text {
  font-size: 1.1rem;
}

